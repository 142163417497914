<template>
  <div>
    <PageHeader :title="title" :items="items" :color="'#6297C3'"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="tabs">
              <div>
                <!-- Tabs links -->
                <ul role="tablist" class="nav nav-tabs" id="__BVID__16__BV_tab_controls_">
                  <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('posts')" :activeClass="(this.$route.name == 'postsList')?'active':''">Posts</router-link>
                  </li>
                  <li role="presentation" class="nav-item">
                    <router-link class="nav-link"  :to="accountLink('slides')" :activeClass="(this.$route.name == 'slidesList')?'active':''">Posters</router-link>
                  </li>
                </ul>
              </div>
              <!-- Content of the tab -->
              <div class="tab-content">
                <div role="tabpanel" aria-hidden="false" class="tab-pane active">
                  <slot/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "../../components/Page-header";


/**
 * center profile component
 */
export default {
  name: 'Post-List-Layout',
  components: {
    PageHeader,
  },
  created(){
  },
  head () {
    return {
      title: `${this.title}`,
    }
  },


  data () {
    return {
      title: 'Posts-Lists',
      items: [
        {
          text: 'Work Center',
          href: '/home'
        },
        {
          text: 'Posts',
          href: '/posts/posts'
        },
        {
          text: 'Posts-list',
          active: true
        }
      ],

    }
  },
  methods: {
    accountLink ($tab) {
      return '/posts/' + $tab
    },
  }
}
</script>

<style lang="scss" scoped>
.flex-container {
  display: flex;
  justify-content: flex-start;
}

.buttons {
  color: blue;
}

.QR {
  width: 50%;
  padding: 9px;
}

.Dropdown {
  position: absolute;
  right: 30px;
  margin-top: 0;

}

#sidebar-right {
  background: #fff !important;
}

.b-sidebar.b-sidebar-right {
  width: 50%;
}

header.b-sidebar-header {
  box-shadow: 2px -3px 5px;
  z-index: 999;
}

footer.b-sidebar-footer {
  display: flex;
  justify-content: flex-end;
  padding: 5px;
  box-shadow: 2px 3px 5px;
  z-index: 999;
}

#sidebar-right___title__ {
  font-size: 20px;

  i {
    margin-right: 5px;
  }
}

.sidebar-footer {
  button {
    i {
      color: #FFF;
    }
  }
}

</style>
